import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { createWidget } from '@typeform/embed'
import { FetchRequest } from "@rails/request.js"


export default class extends Controller {
  static values = {
    formId: String,
    hiddenFields: Object
  }

  connect() {
    // this.isPageSecure = true
    
    this.typeform_start()
  }

  secure_page() {
    // https://stackoverflow.com/questions/1760250/how-to-tell-if-browser-tab-is-active
    // https://stackoverflow.com/questions/7317273/warn-user-before-leaving-web-page-with-unsaved-changes
    let context = this
    window.addEventListener("beforeunload", function (e) {
      console.log("###############");
      console.log(context.isPageSecure);
      console.log("###############");
      if (!context.isPageSecure) {
        return undefined;
      }

      e.preventDefault();
      var confirmationMessage = 'It looks like you have been editing something. '
                              + 'If you leave before saving, your changes will be lost.';
  
      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    });
  }

  typeform_start(event) {
    this.secure_page()
    createWidget(this.formIdValue, {
      container: document.querySelector('#form'),
      hideHeaders: true,
      inlineOnMobile: true,
      onSubmit: this.onTypeformSubmit.bind(this),
      hidden: this.hiddenFieldsValue,
      iframeProps: {style: "width: '100%'"}
    })
  }

  onTypeformSubmit(event) {
    let ss_id         = window.location.pathname.split('/').pop();
    let res_id        = event.response_id

    this.isPageSecure = false

    let url = window.location.origin
    url    += "/ss"
    url    += "/course_processing"
    url    += `?ss=${ss_id}`
    url    += `&fid=${this.formIdValue}`
    url    += `&res_id=${res_id}`
    url    += `&uuid=${this.hiddenFieldsValue.uuid}`

    Turbo.clearCache()
    Turbo.visit(url)
  }
}
